.bgGlow::before {
  height: 300%;
  top: -100%;
  background-image: radial-gradient(
    closest-side,
    #e91bfa4d 0%,
    transparent 65%
  );
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  mix-blend-mode: screen;
  z-index: -100;
}

.framesSlant {
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
}

.framesSlantPx {
  -webkit-clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 95%);
  clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 95%);
}
.framesSlantFlipped {
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 95%, 0 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 95%, 0 100%);
}

.framesSlantEnd {
  -webkit-clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0% 100%);
}
.framesSlantEndFlipped {
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
}

.lazyWrapper {
  position: relative;
  overflow: hidden;
}

.lazySource {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.lazyLoaded {
  opacity: 1;
}

.lqip {
  display: block;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 1;
}

.lqipLoaded {
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
  animation-delay: 0.15s;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media handheld and (orientation: landscape) {
  /* applies to mobiles in landscape mode */
  .bgGlow::before {
    height: 200%;
    top: -50%;
  }
}
@media handheld and (orientation: portrait) {
  /* applies to mobiles in portrait mode */
  .bgGlow::before {
    height: 200%;
    top: -50%;
  }
}

@media (hover: none) {
  .bgGlow::before {
    height: 200%;
    top: -50%;
  }
}
