.framesLoader {
  object-fit: contain;
  animation-timing-function: linear;
}

.framesLoader.animated {
  -webkit-animation: filter-animation 2s linear infinite;
  animation: filter-animation 2s linear infinite;
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: brightness(75%);

    -webkit-transform: scale(0.85);
  }

  50% {
    -webkit-filter: brightness(133%) drop-shadow(0px 0px 20px white);

    -webkit-transform: scale(1);
  }

  100% {
    -webkit-filter: brightness(75%);

    -webkit-transform: scale(0.85);
  }
}

@keyframes filter-animation {
  0% {
    filter: brightness(75%);

    transform: scale(0.85);
  }

  50% {
    filter: brightness(133%) drop-shadow(0px 0px 20px white);

    transform: scale(1);
  }

  100% {
    filter: brightness(75%);

    transform: scale(0.85);
  }
}
